import React from "react";
import styles from "./BenefitSection.module.scss";

import Image from "./assets/benefit-image.jpg"
import HeartIcon from "./assets/heart.svg"
import { RegisterInvite } from "../common/RegisterInivite/RegisterInvite";


export const BenefitSection = ({
  onSubmit = () => {},
}) => {

  return (
    <div id={'benefits'} className={styles.background}>
        <div className={styles.line}>&nbsp;</div>
        <div className={styles.shadow_container}>
          <div className={styles.container}>

            <div className={styles.mobileContainer} >
                <h2 className={styles.exclusiveMobile}>exclusive</h2>
                <h3 className={styles.benefitsMobile}>
                  <span className={styles.mobile}><span className={styles.ben}>ben</span>efits</span>
                </h3>
                <h4 className={styles.includedMobile}>included</h4>
                <img className={styles.imageMobile} src={Image} alt="Benefit" />

            </div>

              <div className={styles.desktopImageContainer}>
                <img className={styles.image} src={Image} alt="Benefit" />
              </div>

              <div className={styles.desktopImageContainer}>
                <h2 className={styles.exclusive}>exclusive</h2>
                <h3 className={styles.benefits}>
                  <span className={styles.mobile}><span className={styles.ben}>ben</span>efits</span>
                  <span className={styles.desktop}><span className={styles.b}>b</span>enefits</span>
                </h3>
                <h4 className={styles.included}>included</h4>
                <h5 className={styles.heading}>Featured benefits</h5>
              </div>

          </div>
          
          <div className={styles.containerBottom}>

            <div className={styles.row}>

              <h5 className={styles.headingMobile}>Featured benefits</h5>

              <div style={{'display': 'flex', flexDirection: 'column', flex: 1}}>
                <div className={styles.perkRow}>
                  <img className={styles.heart} src={HeartIcon} alt="Heart Icon" />
                  <p className={styles.perk}>
                    Signed Joss Stone exclusive Never Forget My Love t-shirt for purchases of $75 or over
                  </p>
                </div>

                <div className={styles.perkRow}>
                  <img className={styles.heart} src={HeartIcon} alt="Heart Icon" />
                  <p className={styles.perk}>
                    Signed Joss Stone exclusive Never Forget My Love t-shirt, plus a personal message from Joss for purchases of $250 or over
                  </p>
                </div>

                <div className={styles.perkRow}>
                  <img className={styles.heart} src={HeartIcon} alt="Heart Icon" />
                  <p className={styles.perk}>
                    Pre-recorded guitar lesson from Dave Stewart for the first 20 buyers who spend $500 or over
                  </p>
                </div>
                
                <div className={styles.perkRow}>
                  <img className={styles.heart} src={HeartIcon} alt="Heart Icon" />
                  <p className={styles.perk}>
                    SongBits exclusive signed Never Forget My Love color variant vinyl for the first 600 buyers who spend $500 or over
                  </p>
                </div>
              </div>

              <div style={{'display': 'flex', flexDirection: 'column', flex: 1}}>
                <div className={styles.perkRow}>
                  <img className={styles.heart} src={HeartIcon} alt="Heart Icon" />
                  <p className={styles.perk}>
                    2x Premium Level tickets to a future Joss Stone or Dave Stewart show of your choice for purchases of $850 or over
                  </p>
                </div>

                <div className={styles.perkRow}>
                  <img className={styles.heart} src={HeartIcon} alt="Heart Icon" />
                  <p className={styles.perk}>
                    An exclusive, customized name plate plaque (approx. 32.5" x 22.5") presented in the fans name, plus the SongBits exclusive Never Forget My Love color variant vinyl signed by Joss and Dave, and an exclusive never-before-seen image of Joss and Dave by photographer Dave Hogan for purchases of $3000 or over
                  </p>
                </div>


                <div className={styles.perkRow}>
                  <img className={styles.heart} src={HeartIcon} alt="Heart Icon" />
                  <p className={styles.perk}>
                    One-on-one guitar & songwriting lesson from Dave Stewart <span className={styles.bold}>(Grammy Award Winner, Rock n' Roll Hall of Fame)</span> for the first purchase of $5000 or over*
                  </p>
                </div>

                <div className={styles.waitlistContainer}>
                  <RegisterInvite customClass={'benefit-waitlist'} onSubmit={onSubmit} />
                </div>

                <div className={styles.perkRow}>
                  <p className={styles.perkSmall}>*Must be able to play guitar at a basic level as a minimum requirement to claim</p>
                </div>
              </div>

            </div>
          </div>
        </div>
    </div>
  )

};