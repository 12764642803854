import React from "react";
import styles from "./SpotifyPlayer.module.scss";


export const SpotifyPlayer = () => {

  return (
    <>
      <iframe title='player' className={styles.iFrame} src="https://open.spotify.com/embed/track/63KVRfA7COiRBVnZAfbPxU?utm_source=generator" width="348px" height="81"  allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </>
  );
};
