import React, { useState, useEffect } from "react"; 
//import { useSearchParams } from 'react-router-dom';

// components
//import { useWindowSize } from "../../../utils/useWindowSize";
//import CheckInboxModal from "../../modals/CheckInboxModal/CheckInboxModal";
// import SignInModal from "../../modals/SignInModal/SignInModal";

import { FirstSection } from "../High/FirstSection";
import { SongSection } from "../High/SongSection";
import { StatSection } from "../High/StatSection";
//import { VideoSection } from "../High/VideoSection";
import { ArtistSection } from "../High/ArtistSection";
import { BenefitSection } from "../High/BenefitSection";
import { StatisticsSection } from "../High/StatisticsSection";
import { CharitySection } from "../High/CharitySection";
import { Footer } from "../High/Footer";

//import { VideoModal } from "../modals";
import CheckInboxModal from "../modals/CheckInboxModal/CheckInboxModal";
//import { checkLive } from "../../utils/dateCheck";
//import { getData } from "../../api";


export const HighProfile = () => {

  //const { width } = useWindowSize();
  //const [searchParams] = useSearchParams();

  //const [loading, setLoading] = useState(true);
  
  const [showCheckInboxModal, setShowCheckInboxModal] = useState(false);

  //const [showSignInModal, setShowSignInModal] = useState(false);
  //const [data, setData] = useState({});
  //const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    
    
    /*getData(process.env.REACT_APP_SONG_ID).then((res) => {

      const response = res.data

      const is_live = checkLive(response.release_at.split('.')[0]);

      response['now_epoch'] = is_live.now_epoch
      response['date_epoch'] = is_live.date_epoch
      response['release_date'] = is_live.release_date
      response['date_check'] = is_live.now_epoch > is_live.date_epoch
      response['video_url'] = ''
  
      setData(response)
  
      //setLoading(false)

    })*/
    
  }, []);
  
  return (
    <>
      <FirstSection />
      <StatSection 
        data={{
          statTitleOne: 'artist records sold',
          statOne: '15 million',
          statTitleTwo: 'artist streams',
          statTwo: '1 billion',
        }}
      />
      <SongSection onSubmit={() => {setShowCheckInboxModal(true)}} />
      <StatSection 
        data={{
          statTitleOne: 'song video plays',
          statOne: '2.5 million',
          statTitleTwo: 'song streams',
          statTwo: '4.3 million',
        }}
      />
      <ArtistSection onSubmit={() => {setShowCheckInboxModal(true)}} />
      <StatSection 
        data={{
          statTitleOne: 'social media followers',
          statOne: '2.4 million',
          statTitleTwo: 'awards',
          statTwo: 'Grammy, Brits',
        }}
        />
      <BenefitSection onSubmit={() => {setShowCheckInboxModal(true)}} />
      <StatSection 
        data={{
          statTitleOne: 'shows played',
          statOne: '400+',
          statTitleTwo: 'performed in',
          statTwo: '201+ countries',
        }}
        />
      <StatisticsSection onSubmit={() => {setShowCheckInboxModal(true)}} />
      <StatSection 
        data={{
          statTitleOne: 'genre',
          statOne: 'R&B, Soul',
          statTitleTwo: 'bragging rights',
          statTwo: 'Priceless',
        }}
        />
      <CharitySection onSubmit={() => {setShowCheckInboxModal(true)}} />
      <Footer />

      <CheckInboxModal
        show={showCheckInboxModal}
        onClose={() => setShowCheckInboxModal(false)}
      />
    </>
  );
};
