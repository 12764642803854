import React from "react";
import styles from "./StatSection.module.scss";


export const StatSection = ({data}) => {

  return (
    <>
      <div className={styles.line}>&nbsp;</div>
      <div className={styles.statSection}>
      <div
          className={styles.statItemConatiner}
        >
          <div className={styles.statItem}>
            <p className={styles.statTitle}>{data.statTitleOne}</p>
            <p className={styles.stat}>{data.statOne}</p>
          </div>
          <div className={styles.statItem}>
            <p className={styles.statTitle}>{data.statTitleTwo}</p>
            <p className={styles.stat}>{data.statTwo}</p>
          </div>
          <div className={styles.statItem}>
          </div>
        </div>
      </div>
    </>
  );
};
